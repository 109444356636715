import { createSlice } from '@reduxjs/toolkit';

import {
  createNewDashboardVersionSuccess,
  deleteCurrentDashboardDraftSuccess,
  fetchDashboardVersionsRequest,
  fetchDashboardVersionsSuccess,
  publishNewDashboardVersionSuccess,
} from 'actions/dashboardV2Actions';
import * as RD from 'remotedata';
import { DashboardVersion } from 'types/dashboardVersion';

import { saveExploreDraft } from './thunks/resourceSaveThunks';
import { revertResourceToVersionThunk } from './thunks/versionManagementThunks';

interface DashboardVersionsReducerState {
  versions: RD.ResponseData<DashboardVersion[]>;
}

const initialState: DashboardVersionsReducerState = {
  versions: RD.Idle(),
};

const dashboardVersionsSlice = createSlice({
  name: 'dashboardVersions',
  initialState,
  reducers: {
    clearDashboardVersions: (state) => {
      state.versions = RD.Idle();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardVersionsRequest, (state) => {
        state.versions = RD.Loading();
      })
      .addCase(fetchDashboardVersionsSuccess, (state, { payload }) => {
        state.versions = RD.Success(
          [...payload.versions].sort((a, b) => b.version_number - a.version_number),
        );
      })
      .addCase(createNewDashboardVersionSuccess, (state, { payload }) => {
        if (!RD.isSuccess(state.versions)) return;
        state.versions.data.unshift(payload.dashboard_version);
      })
      .addCase(revertResourceToVersionThunk.fulfilled, (state, { payload, meta }) => {
        const { new_version } = payload;
        if (
          !meta.arg.isExplore ||
          !('configuration' in new_version) ||
          !RD.isSuccess(state.versions)
        )
          return;

        const index = state.versions.data.findIndex(
          (version) => version.version_number === new_version.version_number,
        );

        // Remove an active draft if present, since we are pushing a new draft based on the revert
        if (index >= 0) {
          state.versions.data.splice(index, 1);
        }
        state.versions.data.unshift(new_version);
      })
      .addCase(saveExploreDraft.fulfilled, (state, { payload }) => {
        if (!RD.isSuccess(state.versions)) return;
        state.versions.data[0].edit_version_number = payload.edit_version_number;
        state.versions.data[0].configuration = payload.configuration;
      })
      .addCase(publishNewDashboardVersionSuccess, (state, { payload: { dashboard_version } }) => {
        if (!RD.isSuccess(state.versions)) return;

        const publishedVersion = state.versions.data.find(({ id }) => id === dashboard_version.id);
        if (!publishedVersion) return;

        publishedVersion.is_draft = dashboard_version.is_draft;
        publishedVersion.published_by_id = dashboard_version.published_by_id;
        publishedVersion.change_comments = dashboard_version.change_comments;
        publishedVersion.version_saved_at = dashboard_version.version_saved_at;
        publishedVersion.configuration = dashboard_version.configuration;
      })
      .addCase(deleteCurrentDashboardDraftSuccess, (state, { payload }) => {
        if (!RD.isSuccess(state.versions)) return;

        const deletedVersionNumber = payload.postData.version_number;

        const index = state.versions.data.findIndex(
          (version) => version.version_number === deletedVersionNumber,
        );

        if (index >= 0) state.versions.data.splice(index, 1);
      });
  },
});

export const { clearDashboardVersions } = dashboardVersionsSlice.actions;

export const dashboardVersionsReducer = dashboardVersionsSlice.reducer;
