import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ConfigSection } from 'components/PanelComponents/ConfigSection';
import { PanelListItem } from 'components/PanelComponents/PanelListItem';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { retrieveDrilldownVariables } from 'utils/dashboardDrilldownUtils';
import { getVariableIcon } from 'utils/variableUtils';

interface Props {
  lowerCasedSearchQuery: string;
  variables: DashboardVariableMap;
}

export const DrilldownVariablesSection: FC<Props> = ({ lowerCasedSearchQuery, variables }) => {
  const { currentSourceInfos, dashboardHierarchy } = useSelector((state: ReduxState) => {
    return {
      currentSourceInfos: state.drilldowns.currentSourceInfos,
      dashboardHierarchy: RD.getOrDefault(state.dashboard.dashboardHierarchy, {
        dashboards: {},
        rootDashboardId: 0,
      }),
    };
  });

  const drilldownVariables = useMemo(
    () => retrieveDrilldownVariables(variables, currentSourceInfos, dashboardHierarchy, () => true),
    [currentSourceInfos, dashboardHierarchy, variables],
  );

  const filteredDrilldownVariables = useMemo(() => {
    return drilldownVariables.filter(({ key }) =>
      key.toLocaleLowerCase().includes(lowerCasedSearchQuery),
    );
  }, [drilldownVariables, lowerCasedSearchQuery]);

  return filteredDrilldownVariables.length > 0 ? (
    <ConfigSection
      defaultOpen={drilldownVariables.length > 0}
      icon="chart-line"
      title="Drilldown variables"
      variant="header2">
      {drilldownVariables.map(({ key, value }) => (
        <PanelListItem
          copiable
          key={key}
          leftIcon={getVariableIcon(value)}
          name={key}
          rightElement={value}
        />
      ))}
    </ConfigSection>
  ) : null;
};
