import cx from 'classnames';
import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Dashboard } from 'actions/dashboardActions';
import { IconButton } from 'components/ds';
import * as buttonStyles from 'components/ds/SideSheet/index.css';
import { DashboardDebugger } from 'pages/dashboardPage/DashboardDebugger';
import { AddDataPanelModal } from 'pages/dashboardPage/addDataPanelModal';
import { DashboardEditBanner } from 'pages/dashboardPage/dashboardEditBanner';
import { setSelectedDatasetId } from 'reducers/dashboardEditConfigReducer';
import { getIsFullPageDashboard } from 'reducers/dashboardInteractionsReducer';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { VersionInfo } from 'types/exploResource';

import { EditDashboardLeftPanel } from './EditDashboardLeftPanel';
import { DashboardDatasetEditorDataFetcher } from './dashboardDatasetEditor/dashboardDatasetEditorDataFetcher';
import { FidoDashboardDatasetEditorDataFetcher } from './dashboardDatasetEditor/fidoDashboardDatasetEditorDataFetcher';
import { DeleteElementModal } from './deleteElementModal';
import { EditDashboardPageBody } from './editDashboardBody';
import * as styles from './editDashboardPage.css';
import { EditDataPanelModal } from './editDataPanelModal';

type Props = {
  dashboard: Dashboard;
  dashboardVersionInfo: VersionInfo;
  width?: number;
};

export const EditDashboardPage: FC<Props> = ({ dashboard, dashboardVersionInfo, width }) => {
  const dispatch = useDispatch();
  const {
    editableDashboard,
    allDashboardVersions,
    isFullPageDashboard,
    rightPaneOpen,
    selectedDatasetId,
    shouldUseFido,
    isSideBarOpen,
  } = useSelector(
    (state: ReduxState) => ({
      editableDashboard: state.dashboardInteractions.interactionsInfo.isEditing,
      allDashboardVersions: RD.getOrDefault(state.dashboardVersions.versions, []),
      isFullPageDashboard: getIsFullPageDashboard(state.dashboardInteractions),
      rightPaneOpen: state.dashboardInteractions.paneOpenStates.right,
      selectedDatasetId: state.dashboardEditConfig.selectedDatasetId,
      shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
      isSideBarOpen: state.dashboardInteractions.isSidebarOpen,
    }),
    shallowEqual,
  );

  const renderEditor = () => {
    if (!selectedDatasetId) return null;
    return (
      <>
        <div
          className={
            isSideBarOpen ? styles.datasetEditorSidebarOpen : styles.datasetEditorSidebarClosed
          }>
          {shouldUseFido ? (
            <FidoDashboardDatasetEditorDataFetcher />
          ) : (
            <DashboardDatasetEditorDataFetcher />
          )}
        </div>
        <IconButton
          className={cx(buttonStyles.controlButton, styles.closeButton)}
          name="cross"
          onClick={() => dispatch(setSelectedDatasetId(null))}
          style={{
            // override 'pointer events: none' that get set when the dialog is open
            pointerEvents: 'auto',
          }}
        />
      </>
    );
  };

  return (
    <div className={styles.root}>
      {renderEditor()}
      <DashboardEditBanner
        allDashboardVersions={allDashboardVersions}
        dashboard={dashboard}
        dashboardVersionInfo={dashboardVersionInfo}
        inEditMode={editableDashboard}
      />
      {editableDashboard ? null : (
        <div className={styles.previewBanner}>
          This is a preview of&nbsp;<b>{dashboard.name}</b>. Changes will not be saved
        </div>
      )}
      <div className={styles.bodyBelowBanner}>
        <EditDashboardLeftPanel
          dashboardId={dashboard.id}
          isFullPageDashboard={isFullPageDashboard}
          shareLinkTitle={dashboard.share_link_title}
        />
        <EditDashboardPageBody
          dashboard={dashboard}
          versionInfo={dashboardVersionInfo}
          width={width}
        />
        {!isFullPageDashboard && rightPaneOpen ? (
          <div className={styles.rightSide}>
            <DashboardDebugger />
          </div>
        ) : null}
      </div>
      <AddDataPanelModal dashboardId={dashboard.id} />
      <EditDataPanelModal />
      <DeleteElementModal />
    </div>
  );
};
