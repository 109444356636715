import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Dashboard } from 'actions/dashboardActions';
import { DashboardAttribute, ExploreEmailCadence } from 'actions/teamActions';
import { ACTION } from 'actions/types';
import { updateLastSeenAppUpdateId } from 'actions/userActions';
import { EmptyPageActionCallout } from 'components/EmptyPageActionCallout';
import { ViewType } from 'components/PageHeader';
import { IconButton, Modal, Spinner, sprinkles } from 'components/ds';
import { PERMISSIONED_ACTIONS } from 'constants/roleConstants';
import { ROUTES } from 'constants/routes';
import { DisabledDashboardMenu } from 'pages/homeAppPage/DisabledDashboardMenu';
import { createLoadingSelector } from 'reducers/api/selectors';
import { clearDashboardVersions } from 'reducers/dashboardVersionsReducer';
import { getResources } from 'reducers/folderReducer';
import { clearReportBuilderVersions } from 'reducers/reportBuilderEditReducer';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { APP_UPDATE_MAP } from 'shared/AppUpdates/AppUpdateMap';
import { BaseAppUpdate } from 'shared/AppUpdates/BaseAppUpdate';
import { Folder, Resource, ResourcePageType } from 'types/exploResource';
import { getPermissionEntity, getResourceText } from 'utils/exploResourceUtils';
import {
  canUserViewResourceConfigurationMenu,
  doesUserHavePermission,
} from 'utils/permissionUtils';

import { FolderConfigurationMenu } from './FolderConfigurationMenu';
import { FolderItem } from './FolderItem';
import { ResourceConfigurationMenu } from './ResourceConfigurationMenu';
import { ResourceItem } from './ResourceItem';
import * as styles from './styles.css';

type Props = {
  viewType?: ViewType;
  pageType: ResourcePageType;

  dashboardAttributes?: DashboardAttribute[];
  emailCadenceList?: ExploreEmailCadence[];
};

export const ResourceListPage: FC<Props> = ({
  viewType,
  pageType,
  dashboardAttributes,
  emailCadenceList,
}): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isExploreProduct = pageType === ResourcePageType.EXPLORE;

  const { currentUser, folders, isLoading, isError, resources } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      folders: state.folder.folders,
      isLoading: RD.isLoading(state.folder.resourcesStatus),
      isError: RD.isError(state.folder.resourcesStatus),
      resources: getResources(state, isExploreProduct),
      createResourceLoading: createLoadingSelector(
        [ACTION.CREATE_DASHBOARD, ACTION.CLONE_DASHBOARD],
        false,
      )(state),
    }),
    shallowEqual,
  );

  const [resourceUpdating, setResourceUpdating] = useState<number | undefined>();
  const [folderUpdating, setFolderUpdating] = useState<number | undefined>();

  const current_last_seen_id = currentUser.last_seen_app_update_id;
  const new_last_seen_id = Math.max(...Object.keys(APP_UPDATE_MAP).map((id) => Number(id)));

  useEffect(() => {
    // For new users, assign last_seen_id to the most recent app update id so they don't see a ton of updates when they first log in
    if (current_last_seen_id === undefined)
      dispatch(
        updateLastSeenAppUpdateId({
          postData: {
            last_seen_id: new_last_seen_id,
          },
        }),
      );
  }, [dispatch, current_last_seen_id, new_last_seen_id]);

  const resourcePermissionEntity = getPermissionEntity(pageType);

  const userPermissions = currentUser.permissions[resourcePermissionEntity];

  const userCanCreateResource = doesUserHavePermission(
    userPermissions,
    PERMISSIONED_ACTIONS.CREATE,
  );

  const userCanConfigureResource = canUserViewResourceConfigurationMenu(
    currentUser,
    resourcePermissionEntity,
  );

  const rootPath = isExploreProduct ? ROUTES.HOME_APP_PAGE : ROUTES.REPORT_BUILDER;

  const appUpdates = useMemo(() => {
    // We don't want to show app updates if the user is new. This line prevents flickering of the update modal.
    if (current_last_seen_id === undefined) return [];
    const appUpdates: ReactNode[] = [];

    // Sort app updates in reverse order
    const sortedUpdates = Object.entries(APP_UPDATE_MAP).sort(
      (id1, id2) => Number(id2) - Number(id1),
    );
    sortedUpdates.forEach(([id, updateProps]) => {
      // Ignore updates user has already seen or they don't have permission to see
      if (
        Number(id) <= current_last_seen_id ||
        (updateProps?.permissionFn && !updateProps.permissionFn({ user: currentUser }))
      )
        return;
      appUpdates.push(<BaseAppUpdate {...updateProps} />);
    });
    return appUpdates;
  }, [currentUser, current_last_seen_id]);

  if (isError) {
    return (
      <div className={styles.errorLoadingResources}>
        Error Loading {getResourceText(pageType, { plural: true, capitalized: true })}
      </div>
    );
  }

  const renderDotsMenu = (
    resource: Resource,
    emailCadence: ExploreEmailCadence | undefined,
    isCard: boolean,
    isDisabled: boolean,
  ) => {
    if (!userCanConfigureResource) return null;

    const containerStyle = isCard ? styles.dotsMenuContainerCard : styles.dotsMenuContainer;

    const iconStyle = isCard ? styles.dotsMenuIconCard : styles.dotsMenuIcon;

    if (resource.id === resourceUpdating)
      return (
        <div className={containerStyle}>
          <Spinner fillContainer className={iconStyle} size="md" />
        </div>
      );

    if (isDisabled) {
      // Only Dashboard can be disabled
      return (
        <div className={containerStyle} onClick={(e) => e.stopPropagation()}>
          <DisabledDashboardMenu
            dashboard={resource as Dashboard}
            dashboardList={resources as Dashboard[]}
            setLoadingStateForDashboard={(isLoading) =>
              setResourceUpdating(isLoading ? resource.id : undefined)
            }
          />
        </div>
      );
    }

    return (
      <div className={containerStyle} onClick={(e) => e.stopPropagation()}>
        <ResourceConfigurationMenu
          dashboardAttributes={dashboardAttributes}
          emailCadence={emailCadence}
          onDuplicationModalClose={() =>
            dispatch(isExploreProduct ? clearDashboardVersions() : clearReportBuilderVersions())
          }
          pageType={pageType}
          resource={resource}
          resourcePermissionEntity={resourcePermissionEntity}
          setLoadingStateForResource={(isLoading) =>
            setResourceUpdating(isLoading ? resource.id : undefined)
          }
          trigger={<IconButton className={iconStyle} name="ellipsis-vertical" />}
        />
      </div>
    );
  };

  const renderFolderDotsMenu = (folder: Folder, isCard: boolean) => {
    const containerStyle = isCard ? styles.dotsMenuContainerCard : styles.dotsMenuContainer;

    const iconStyle = isCard ? styles.dotsMenuIconCard : styles.dotsMenuIcon;

    if (folder.id === folderUpdating)
      return (
        <div className={containerStyle}>
          <Spinner fillContainer className={iconStyle} size="md" />
        </div>
      );

    return (
      <div className={containerStyle} onClick={(e) => e.preventDefault()}>
        <FolderConfigurationMenu
          folder={folder}
          pageType={pageType}
          setLoadingStateForFolder={(isLoading) =>
            setFolderUpdating(isLoading ? folder.id : undefined)
          }
          trigger={<IconButton className={iconStyle} name="ellipsis-vertical" />}
        />
      </div>
    );
  };

  const viewResource = (resource: Resource) => {
    const emailCadence = isExploreProduct
      ? emailCadenceList?.find((cadence) => cadence.dashboard_template_id === resource.id)
      : undefined;

    const isDisabled = 'disabled' in resource ? resource.disabled ?? false : false;

    return (
      <ResourceItem
        dotsMenu={renderDotsMenu(resource, emailCadence, viewType === ViewType.Card, isDisabled)}
        hasEmailState={emailCadence !== undefined}
        isCard={viewType === ViewType.Card}
        isLoading={isLoading}
        key={resource.id}
        onClickUrl={
          isExploreProduct ? `/dashboard/${resource.id}` : `/report-builder/${resource.id}/datasets`
        }
        resource={resource}
        showPreview={pageType !== ResourcePageType.REPORT_BUILDER}
      />
    );
  };

  const renderList = () => {
    if (resources.length + folders.length === 0 && !isLoading && userCanCreateResource) {
      return (
        <EmptyPageActionCallout
          text={`Get started by creating your first ${getResourceText(pageType)}`}
        />
      );
    }

    return (
      <div className={viewType === ViewType.Card ? sprinkles({ flex: 1 }) : undefined}>
        <div
          className={viewType === ViewType.Card ? styles.cardsGrid : styles.resourceListContainer}>
          {folders.map((folder) => (
            <FolderItem
              dotsMenu={renderFolderDotsMenu(folder, viewType === ViewType.Card)}
              folder={folder}
              isCard={viewType === ViewType.Card}
              isExploreProduct={isExploreProduct}
              key={`${folder.id}-${folder.name}`}
              openFolder={() => !isLoading && history.push(`${rootPath}/${folder.id}`)}
            />
          ))}
          {resources.map(viewResource)}
        </div>
      </div>
    );
  };

  return (
    <div className={sprinkles({ flex: 1 })}>
      <Modal
        isOpen={isExploreProduct && appUpdates.length > 0}
        onClose={() =>
          dispatch(
            updateLastSeenAppUpdateId({
              postData: {
                last_seen_id: new_last_seen_id,
              },
            }),
          )
        }
        size="small"
        title="✨ New in Explo">
        {appUpdates}
      </Modal>
      {renderList()}
    </div>
  );
};
