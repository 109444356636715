import { FC, useState, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Dataset } from 'actions/datasetActions';
import { CardItem } from 'components/PanelComponents/CardItem';
import { sprinkles, Button } from 'components/ds';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { CreateDatasetModal } from 'pages/dashboardPage/dashboardDatasetEditor/modals/CreateDatasetModal';
import { DeleteDatasetModal } from 'pages/dashboardPage/dashboardDatasetEditor/modals/DeleteDatasetModal';
import { EditDatasetModal } from 'pages/dashboardPage/dashboardDatasetEditor/modals/EditDatasetModal';
import { getDataPanelUsageTextMap } from 'pages/dashboardPage/dashboardDatasetEditor/utils';
import { SearchInput } from 'pages/manageDataTablesPage/SearchInput';
import {
  setSelectedDatasetId,
  getEditPageDataPanels,
  getEditPageElements,
} from 'reducers/dashboardEditConfigReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getArchetypeProperties, getEditableDatasets } from 'reducers/selectors';
import { getDatasetName } from 'utils/naming';
import { sortBy } from 'utils/standard';
import { isQueryDependentOnVariable } from 'utils/variableUtils';

enum ModalStatus {
  CREATE_DATASET = 'create_dataset',
  DELETE_DATASET = 'delete_dataset',
  EDIT_DATASET = 'edit_dataset',
  CLOSED = 'closed',
}

type Props = { dashboardId: number; selectedChartDatasetId?: string | number | undefined };

export const DatasetsPanel: FC<Props> = ({ dashboardId, selectedChartDatasetId }) => {
  const dispatch = useDispatch();
  const [modalStatus, setModalStatus] = useState(ModalStatus.CLOSED);
  const [editDatasetId, setEditDatasetId] = useState<string | null>(null);
  const [deleteDatasetId, setDeleteDatasetId] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  const {
    datasetConfigs,
    showUnfilteredDatasetFlag,
    archetypeProperties,
    elements,
    dataPanels,
    datasetData,
  } = useSelector(
    (state: ReduxState) => ({
      archetypeProperties: getArchetypeProperties(state),
      variables: state.dashboardData.variables ?? {},
      datasetConfigs: getEditableDatasets(state),
      showUnfilteredDatasetFlag: state.currentUser.team?.feature_flags.show_unfiltered_dataset_flag,
      elements: getEditPageElements(state.dashboardEditConfig, false),
      dataPanels: getEditPageDataPanels(state.dashboardEditConfig, false),
      datasetData: state.dashboardEditConfig.datasetData,
    }),
    shallowEqual,
  );

  const filteredAndOrderedDatasetItems = useMemo(() => {
    const chartSelectedDataset = selectedChartDatasetId
      ? datasetConfigs[selectedChartDatasetId]
      : undefined;
    const sortedDatasets = sortBy(
      Object.values(datasetConfigs).filter((config) =>
        config.table_name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()),
      ),
      (config) => config.table_name.toLocaleLowerCase(),
    );
    const usageTextMap = getDataPanelUsageTextMap(datasetConfigs, dataPanels, elements);
    const renderDatasetItem = (datasetConfig: Dataset) => {
      const datasetName = getDatasetName(datasetConfig);
      const query = datasetConfig.queryDraft ?? datasetConfig.query ?? '';

      const noCustomerFilter = showUnfilteredDatasetFlag
        ? !isQueryDependentOnVariable(archetypeProperties, datasetConfig)
        : false;

      const isDraft = query != null && query !== datasetConfig.query;
      const isError = !!datasetData[datasetConfig.id]?.error;

      return (
        <CardItem
          alert={
            isError
              ? {
                  tooltipText: 'Query has an error',
                  icon: 'circle-exclamation',
                  iconStyle: sprinkles({ color: 'error' }),
                }
              : isDraft
              ? { tooltipText: 'Query is a draft', icon: 'circle-exclamation-reg' }
              : noCustomerFilter
              ? { tooltipText: 'No customer filter set', icon: 'users-reg' }
              : undefined
          }
          isSelected={chartSelectedDataset?.id === datasetConfig.id}
          key={`dataset-navbar-item-${datasetConfig.id}`}
          leftIcon="table"
          name={datasetName}
          onClick={() => dispatch(setSelectedDatasetId(datasetConfig.id))}
          onDelete={() => {
            setDeleteDatasetId(datasetConfig.id);
            setModalStatus(ModalStatus.DELETE_DATASET);
          }}
          onEdit={() => {
            setEditDatasetId(datasetConfig.id);
            setModalStatus(ModalStatus.EDIT_DATASET);
          }}
          secondaryText={usageTextMap[datasetConfig.id]}
        />
      );
    };
    return sortedDatasets.map((datasetConfig) => renderDatasetItem(datasetConfig));
  }, [
    datasetConfigs,
    archetypeProperties,
    selectedChartDatasetId,
    dataPanels,
    datasetData,
    dispatch,
    showUnfilteredDatasetFlag,
    elements,
    searchQuery,
  ]);

  const renderedContent = useMemo(
    () =>
      filteredAndOrderedDatasetItems.length > 0 ? (
        filteredAndOrderedDatasetItems
      ) : (
        <EmbedText
          body="b1"
          className={sprinkles({ parentContainer: 'fill', flexItems: 'centerColumn' })}
          color="contentSecondary">
          No datasets.
        </EmbedText>
      ),
    [filteredAndOrderedDatasetItems],
  );

  return (
    <div className={sprinkles({ height: 'fill', overflow: 'auto' })}>
      <div className={datasetListContainer}>
        <Button fillWidth icon="plus" onClick={() => setModalStatus(ModalStatus.CREATE_DATASET)}>
          Add a Dataset
        </Button>
        <SearchInput
          className={sprinkles({ paddingX: 'sp1' })}
          onInputChanged={setSearchQuery}
          searchQuery={searchQuery}
        />
        {renderedContent}
      </div>
      {modalStatus === ModalStatus.CREATE_DATASET ? (
        <CreateDatasetModal
          dashboardId={dashboardId}
          datasetConfigs={datasetConfigs}
          onClose={() => setModalStatus(ModalStatus.CLOSED)}
        />
      ) : null}
      {modalStatus === ModalStatus.DELETE_DATASET ? (
        <DeleteDatasetModal
          datasetConfigs={datasetConfigs}
          deleteDatasetId={deleteDatasetId}
          onClose={() => {
            setModalStatus(ModalStatus.CLOSED);
            setDeleteDatasetId(null);
          }}
        />
      ) : null}
      {modalStatus === ModalStatus.EDIT_DATASET ? (
        <EditDatasetModal
          datasetConfigs={datasetConfigs}
          editDatasetId={editDatasetId}
          onClose={() => {
            setModalStatus(ModalStatus.CLOSED);
            setEditDatasetId(null);
          }}
        />
      ) : null}
    </div>
  );
};

const datasetListContainer = sprinkles({
  flexItems: 'column',
  padding: 'sp2',
  gap: 'sp2',
  height: 'fill',
});
