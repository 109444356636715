import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { Input, sprinkles } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';

import { ChartVariables } from './ChartVariables';
import { CustomVariables } from './CustomVariables';
import { CustomerVariables } from './CustomerVariables';
import { DrilldownVariablesSection } from './DrilldownVariablesSection';
import { FilterVariables } from './FilterVariables';

type Props = {
  dashboardId: number;
  // All of the variable types are already read only except for Custom (Embed) Variables
  readOnly?: boolean;
};

export const VariablesPanel: FC<Props> = ({ dashboardId, readOnly }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const variables = useSelector((state: ReduxState) => state.dashboardData.variables ?? {});

  const loweredSearch = searchQuery.toLowerCase();

  return (
    <div className={sprinkles({ height: 'fill', overflow: 'auto' })}>
      <Input
        className={sprinkles({ padding: 'sp2' })}
        leftIcon="search"
        onChange={setSearchQuery}
        placeholder="Search..."
        value={searchQuery}
      />
      <CustomerVariables searchQuery={loweredSearch} />
      <ChartVariables searchQuery={loweredSearch} variables={variables} />
      <FilterVariables searchQuery={loweredSearch} variables={variables} />
      <DrilldownVariablesSection lowerCasedSearchQuery={loweredSearch} variables={variables} />
      <CustomVariables
        dashboardId={dashboardId}
        readOnly={readOnly}
        searchQuery={loweredSearch}
        variables={variables}
      />
    </div>
  );
};
