import { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  ReportBuilderColConfig,
  ReportBuilderDataset,
  UpdateReportBuilderDataset,
} from 'actions/reportBuilderConfigActions';
import { Button, sprinkles } from 'components/ds';
import { ColumnHeader } from 'components/resource/ColumnHeader';
import {
  DatasetColumnSelection,
  DatasetColumnSelectionItem,
} from 'components/resource/DatasetColumnSelection';
import { QuerySection } from 'components/resource/QuerySection';
import { DatasetColumnFormat } from 'pages/ReportBuilderEditor/DatasetEditor/DatasetColumnFormat';
import {
  DatasetModal,
  DatasetModalType,
} from 'pages/ReportBuilderEditor/DatasetEditor/DatasetModal';
import { MetadataInputs } from 'pages/ReportBuilderEditor/DatasetEditor/MetadataInputs';
import { PermissionConfig } from 'pages/ReportBuilderEditor/DatasetEditor/PermissionConfig';
import {
  updateReportBuilderColConfig,
  updateReportBuilderDataset,
} from 'reducers/reportBuilderEditReducer';
import { ReduxState } from 'reducers/rootReducer';
import { saveComputedView } from 'reducers/thunks/fidoThunks';
import * as RD from 'remotedata';
import { fetchAppDataset } from 'reportBuilderContent/thunks/appDataThunks';

import { DatasetCustomAggSection } from './DatasetCustomAggSection';

type Props = { dataset: ReportBuilderDataset; isNew: boolean };

export const DatasetConfig: FC<Props> = ({ dataset, isNew }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState<DatasetModal>();
  const [hasShownSaveModal, setHasShownSaveModal] = useState(false);
  const { shouldUseFido, schemas, datasetData } = useSelector(
    (state: ReduxState) => ({
      shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
      schemas: state.parentSchemas,
      datasetData: state.reportBuilderEdit.datasetData,
    }),
    shallowEqual,
  );

  useEffect(() => {
    setOpenModal(undefined);
  }, [dataset.id]);

  // Re-run and save the query when the schema changes
  const currentSchemaId = shouldUseFido
    ? dataset.namespace_id ?? dataset.parent_schema_id
    : dataset.parent_schema_id;
  useEffect(() => {
    dispatch(fetchAppDataset({ datasetId: dataset.id, save: true }));
  }, [currentSchemaId, dataset.id, dispatch]);

  const fetchDataset = (save: boolean) =>
    dispatch(fetchAppDataset({ datasetId: dataset.id, save }));

  const updateConfig = (colName: string, config: Partial<ReportBuilderColConfig>) => {
    dispatch(
      updateReportBuilderColConfig({
        datasetId: dataset.id,
        colName,
        config,
      }),
    );
  };

  const updateDataset = (payload: UpdateReportBuilderDataset) => {
    const descriptionPayload = payload.description ?? dataset.description;
    shouldUseFido
      ? dispatch(
          saveComputedView({
            '@type': 'computed-view',
            id: dataset.fido_id ?? '',
            namespaceId:
              RD.getOrDefault(schemas.usedParentSchemas, []).find(
                (s) => s.id === (payload.schemaId ?? dataset.parent_schema_id),
              )?.fido_id ?? '',
            name: payload.name ?? dataset.name,
            description: descriptionPayload.length === 0 ? null : descriptionPayload,
            permissions: payload.permissions ?? dataset.permissions,
            columnDefinitions: [],
            query: dataset.query,
            cacheEvictionPolicy: null,
          }),
        )
      : dispatch(updateReportBuilderDataset(payload));
  };

  const selectSchema = (schemaId: number) => updateDataset({ datasetId: dataset.id, schemaId });

  const handleNewValueSubmitted = (params: Partial<UpdateReportBuilderDataset>) =>
    updateDataset({ datasetId: dataset.id, ...params });

  const isUnsavedSchema = !dataset.schema?.length;
  const showSaveModal = !isUnsavedSchema && !hasShownSaveModal;
  const datasetDatum = datasetData?.[dataset.id];
  const showColumnConfigOptions = !datasetDatum?.error && !datasetDatum?.loading;

  return (
    <>
      <ColumnHeader title="Configuration">
        <Button
          icon="trash"
          onClick={() => setOpenModal({ type: DatasetModalType.DELETE })}
          tooltipProps={{ text: `Delete Dataset ${dataset.name}` }}
          variant="destructive"
        />
      </ColumnHeader>
      <div className={sprinkles({ flex: 1, width: 'fill', overflowY: 'auto' })}>
        <MetadataInputs
          defaultIsOpen={isNew}
          handleNewValueSubmitted={handleNewValueSubmitted}
          initialDescription={dataset.description}
          initialName={dataset.name}
        />
        <PermissionConfig
          handleNewValueSubmitted={handleNewValueSubmitted}
          initialPermissions={dataset.permissions}
          key={dataset.id}
        />
        <QuerySection
          dataset={dataset}
          getPreview={() => fetchDataset(false)}
          onSelectSchema={(schemaId) =>
            showSaveModal
              ? setOpenModal({
                  type: DatasetModalType.SELECT_SCHEMA,
                  schemaIdToSelect: schemaId,
                  isUnsavedSchema,
                })
              : selectSchema(schemaId)
          }
          saveQuery={() =>
            showSaveModal
              ? setOpenModal({
                  type: DatasetModalType.SAVE,
                  isUnsavedSchema: !dataset.schema?.length,
                })
              : fetchDataset(true)
          }
        />
        {showColumnConfigOptions && dataset.schema?.length ? (
          <>
            <DatasetColumnSelection
              isNewDataset={isNew}
              sectionText="Select which columns will be visible to your end users. Default filters will be filters exposed to the customer, regardless of if the columns are visible to them or not."
              toggleName="Default filter">
              {dataset.schema.map((col) => {
                const columnConfig = dataset.columnConfigs[col.name];
                if (!columnConfig) return null;

                return (
                  <DatasetColumnSelectionItem
                    alwaysShowToggle
                    column={col}
                    isVisible={columnConfig.isVisible}
                    key={col.name}
                    onToggle={(newVal) => updateConfig(col.name, { showDefaultFilter: newVal })}
                    onVisibilityToggle={(newVal) => updateConfig(col.name, { isVisible: newVal })}
                    toggleOn={!!columnConfig.showDefaultFilter}
                  />
                );
              })}
            </DatasetColumnSelection>
            <DatasetColumnFormat dataset={dataset} updateConfig={updateConfig} />
            <DatasetCustomAggSection
              dataset={dataset}
              onDelete={(aggId) =>
                setOpenModal({ type: DatasetModalType.DELETE_CUSTOM_AGG, aggId })
              }
            />
          </>
        ) : null}
      </div>
      <DatasetModal
        datasetId={dataset.id}
        openModal={openModal}
        setHasShownSaveModal={setHasShownSaveModal}
        setOpenModal={setOpenModal}
        updateDataset={updateDataset}
      />
    </>
  );
};
