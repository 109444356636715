import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { fetchDashboardAttributes } from 'actions/dashboardAttributesActions';
import { fetchEmailCadenceList } from 'actions/emailActions';
import { ViewType } from 'components/PageHeader';
import { PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { ReduxState } from 'reducers/rootReducer';
import { listDashboardFolderContents } from 'reducers/thunks/resourceThunks';
import * as RD from 'remotedata';
import { ResourceListPage } from 'shared/ExploResource/ResourceListPage';
import { ResourceListPageWrapper } from 'shared/ExploResource/ResourceListPageWrapper';
import {
  RESOURCES_PER_CARD_PAGE,
  RESOURCES_PER_LIST_PAGE,
  VIEW_TYPE_EXPLORE_KEY,
} from 'shared/ExploResource/constants';
import { pageView } from 'telemetry/exploAnalytics';
import { ResourcePageType } from 'types/exploResource';
import { canUserModifyResource } from 'utils/permissionUtils';

export default function HomeAppPage(): JSX.Element {
  const {
    params: { folderId },
  } = useRouteMatch<{ folderId: string }>();
  const dispatch = useDispatch();

  const [currPage, setCurrPage] = useState(1);
  const [viewType, setViewType] = useLocalStorage<ViewType>(VIEW_TYPE_EXPLORE_KEY, ViewType.List);

  const { currentUser, dashboardAttributes, emailCadences } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      dashboardAttributes: state.dashboardAttributes.attributes,
      emailCadences: state.emailCadence.cadences,
    }),
    shallowEqual,
  );

  // on mount
  useEffect(() => {
    document.title = 'Explo | Home';
    pageView('Home App (Dashboards)');
  }, []);

  const teamId = currentUser.team?.id;

  // load dashboards and folders
  useEffect(() => {
    const maybeId = parseInt(folderId || '');
    const id = isNaN(maybeId) ? undefined : maybeId;
    const resourcesPerPage =
      viewType === ViewType.List ? RESOURCES_PER_LIST_PAGE : RESOURCES_PER_CARD_PAGE;
    dispatch(listDashboardFolderContents({ id, page: currPage, page_size: resourcesPerPage }));
  }, [folderId, dispatch, viewType, currPage]);

  // load email cadence list
  useEffect(() => {
    if (!RD.isIdle(emailCadences) || !teamId) return;
    dispatch(fetchEmailCadenceList({ id: teamId }));
  }, [emailCadences, teamId, dispatch]);

  // load dashboard attributes
  useEffect(() => {
    if (
      RD.isIdle(dashboardAttributes) &&
      canUserModifyResource(currentUser.permissions[PERMISSIONED_ENTITIES.DASHBOARD])
    )
      dispatch(fetchDashboardAttributes());
  }, [dashboardAttributes, currentUser.permissions, dispatch]);

  return (
    <ResourceListPageWrapper
      currPage={currPage}
      pageType={ResourcePageType.EXPLORE}
      setCurrPage={setCurrPage}
      setViewType={setViewType}
      viewType={viewType}>
      <ResourceListPage
        dashboardAttributes={RD.getOrDefault(dashboardAttributes, [])}
        emailCadenceList={RD.getOrDefault(emailCadences, [])}
        pageType={ResourcePageType.EXPLORE}
        viewType={viewType}
      />
    </ResourceListPageWrapper>
  );
}
