import { FC } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import sqlFormatter from 'sql-formatter';

import { Dataset } from 'actions/datasetActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { VERSION_COMPARISON_HEADER_CLASS } from 'components/VersionComparisonModal/index.css';
import { getDatasetName } from 'components/VersionComparisonModal/utils';
import { sprinkles } from 'components/ds';

interface Props {
  previousVersionDataset: Dataset | ReportBuilderDataset | undefined;
  previousVersionNumber: number;
  currentVersionDataset: Dataset | ReportBuilderDataset | undefined;
  currentVersionNumber: number;
}

export const DatasetComparisonSection: FC<Props> = ({
  previousVersionDataset,
  previousVersionNumber,
  currentVersionDataset,
  currentVersionNumber,
}) => {
  const formattedPreviousQuery = formatQuery(previousVersionDataset?.query || '');
  const formattedCurrentQuery = formatQuery(currentVersionDataset?.query || '');
  const datasetName = currentVersionDataset
    ? getDatasetName(currentVersionDataset)
    : previousVersionDataset
    ? getDatasetName(previousVersionDataset)
    : '';

  return (
    <>
      <div className={VERSION_COMPARISON_HEADER_CLASS}>{datasetName}</div>
      <div
        className={sprinkles({
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          marginLeft: 'sp3',
          marginBottom: 'sp1',
        })}>
        <span className={sprinkles({ width: 'half' })}>Version {previousVersionNumber}</span>
        <span>Version {currentVersionNumber}</span>
      </div>
      <ReactDiffViewer
        splitView
        compareMethod={DiffMethod.LINES}
        newValue={formattedCurrentQuery}
        oldValue={formattedPreviousQuery}
      />
    </>
  );
};

const formatQuery = (query: string): string => {
  return sqlFormatter.format(query, {
    indent: '    ',
  });
};
