import produce from 'immer';
import { useDispatch } from 'react-redux';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { SettingHeader } from 'components/SettingHeader';
import { Select, sprinkles } from 'components/ds';
import { COLOR_CATEGORY_FILTER_SUFFIX } from 'constants/dashboardConstants';
import { DATE_PART_INPUT_AGG, NUMBER_TYPES } from 'constants/dataConstants';
import {
  Aggregation,
  BAR_CHART_TYPES,
  CategoryChartColumnInfo,
  OPERATION_TYPES,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { DEFAULT_Y_AXIS_FORMAT_INDEX } from 'pages/dashboardPage/charts/utils/multiYAxisUtils';
import { updateVariableThunk } from 'reducers/thunks/dashboardDataThunks/variableUpdateThunks';
import { showCustomToast } from 'shared/sharedToasts';
import { AppToaster } from 'toaster';
import { AggregationType } from 'types/columnTypes';
import { DashboardElement } from 'types/dashboardTypes';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { DatasetSchema, DatasetColumn } from 'types/datasets';
import { PivotAgg } from 'types/dateRangeTypes';
import { DEFAULT_CUSTOM_FORMULA } from 'utils/V2ColUtils';
import { findMatchingAggColIdx, findMatchingAgg } from 'utils/aggUtils';
import { createColorCol, handleColorColumnAddition } from 'utils/colorColUtils';
import {
  getNewAggCol,
  resolveCategoryColDropped,
  resolveAggColDropped,
  filterForValidDateGroupElements,
} from 'utils/dataPanelColUtils';
import { some } from 'utils/standard';

import DroppableColumnSection from './droppable/DroppableColumnSection';

type Props = {
  instructions: V2TwoDimensionChartInstructions;
  canSelectSmartGrouping?: boolean;
  chartType: OPERATION_TYPES;
  categoryName?: string;
  aggName?: string;
  colorName?: string;
  maxAggs?: number;
  supportColor?: boolean;
  loading?: boolean;
  dashboardElements?: DashboardElement[];
  dashboardParams?: Record<string, DashboardParam>;
  dataPanelProvidedId?: string;
  enableMultipleColorCols?: boolean;
  supportSecondaryGroup?: boolean;
  secondaryGroupName?: string;
  schema: DatasetSchema;
  renderColorBeforeAgg?: boolean;
};

export default function TwoDimensionVizConfig({
  aggName = 'Y-Axis',
  canSelectSmartGrouping,
  categoryName = 'X-Axis',
  chartType,
  colorName = 'Group By',
  instructions,
  maxAggs,
  supportColor,
  loading,
  dashboardElements,
  dashboardParams,
  dataPanelProvidedId,
  enableMultipleColorCols,
  supportSecondaryGroup,
  secondaryGroupName = 'Clusters',
  schema,
  renderColorBeforeAgg,
}: Props) {
  const dispatch = useDispatch();

  const aggColumns = instructions.aggColumns || [];

  const updateGrouping = (newGrouping: CategoryChartColumnInfo | undefined) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.groupingColumn = newGrouping;
    });
    dispatch(updateVisualizeOperation(newInstructions, chartType));
  };

  const onAggregationColumnAdded = (
    newCol: DatasetColumn,
    oldColName?: string,
    oldColAggType?: AggregationType,
  ) => {
    const newInstructions = produce(instructions, (draft) => {
      // This is case we are adding a new column
      if (oldColName === undefined) {
        draft.aggColumns = resolveAggColDropped(
          newCol,
          (toastInfo) => AppToaster.show(toastInfo),
          draft.aggColumns || [],
          maxAggs,
          draft.yAxisFormats?.[DEFAULT_Y_AXIS_FORMAT_INDEX].id,
        );
        return;
      }
      // This is case we are replacing an old column
      if (!draft.aggColumns) return;
      const newAggCol = getNewAggCol(
        newCol,
        (toastInfo) => AppToaster.show(toastInfo),
        draft.aggColumns,
        draft.yAxisFormats?.[DEFAULT_Y_AXIS_FORMAT_INDEX].id,
      );
      if (newAggCol === undefined) return;
      const removedColIndex = findMatchingAggColIdx(draft.aggColumns, oldColName, oldColAggType);
      if (removedColIndex === -1) return;
      draft.aggColumns.splice(removedColIndex, 1, newAggCol);
    });
    dispatch(updateVisualizeOperation(newInstructions, chartType));
  };

  const onColorColumnAdded = (newCol: DatasetColumn, oldColName?: string) => {
    const newInstructions = produce(instructions, (draft) => {
      if (some(draft.colorColumnOptions, (opt) => opt.column.name === newCol.name)) {
        showCustomToast(
          'The selected column option is already present for this column. Duplicates are not allowed.',
          { icon: 'warning-sign' },
        );
        return;
      }
      dispatch(
        updateVariableThunk({
          varName: dataPanelProvidedId + COLOR_CATEGORY_FILTER_SUFFIX,
          newValue: newCol.name,
        }),
      );
      // This is case we are adding a new column
      if (oldColName === undefined) {
        draft.colorColumnOptions = handleColorColumnAddition(
          newCol,
          draft.colorColumnOptions,
          enableMultipleColorCols,
        );
        return;
      }
      // This is case we are replacing an old column
      if (!draft.colorColumnOptions) return;
      const removedColIndex = draft.colorColumnOptions.findIndex(
        (col) => col.column.name === oldColName,
      );
      if (removedColIndex === -1) return;
      draft.colorColumnOptions.splice(removedColIndex, 1, createColorCol(newCol));
    });
    dispatch(updateVisualizeOperation(newInstructions, chartType));
  };

  const renderColorCol = () => {
    if (!supportColor && !renderColorBeforeAgg) return null;
    return (
      <>
        <SettingHeader name={colorName} />
        <DroppableColumnSection
          columns={instructions.colorColumnOptions || []}
          dashboardParams={dashboardParams}
          disableEdits={loading}
          maxCols={enableMultipleColorCols ? undefined : 1}
          onColAdded={onColorColumnAdded}
          onColOptionChanged={(option, name) => {
            if (!name) return;
            const newInstructions = produce(instructions, (draft) => {
              const col = draft.colorColumnOptions?.find(({ column }) => column.name === name);
              if (col) col.bucket = { id: option.id as PivotAgg, variableId: option.variableId };
            });
            dispatch(updateVisualizeOperation(newInstructions, chartType));
          }}
          onRemoveCol={({ name }) => {
            const newInstructions = produce(instructions, (draft) => {
              if (!draft.colorColumnOptions || draft.colorColumnOptions.length === 1) {
                draft.colorColumnOptions = undefined;
              } else {
                draft.colorColumnOptions = draft.colorColumnOptions.filter(
                  (col) => col.column.name !== name,
                );
              }
            });

            dispatch(
              updateVariableThunk({
                varName: dataPanelProvidedId + COLOR_CATEGORY_FILTER_SUFFIX,
                newValue: newInstructions.colorColumnOptions?.[0].column.name,
              }),
            );
            dispatch(updateVisualizeOperation(newInstructions, chartType));
          }}
          required={renderColorBeforeAgg}
          schema={schema}
        />
      </>
    );
  };

  const isBarChartGroupingColumn =
    BAR_CHART_TYPES.has(chartType) &&
    NUMBER_TYPES.has(instructions.categoryColumn?.column?.type ?? '');

  return (
    <div>
      <SettingHeader name={categoryName} />
      <DroppableColumnSection
        required
        canSelectSmartGrouping={canSelectSmartGrouping}
        columns={instructions.categoryColumn ? [instructions.categoryColumn] : []}
        dashboardElements={dashboardElements}
        dashboardParams={dashboardParams}
        disableEdits={loading}
        isBarChartGroupingColumn={isBarChartGroupingColumn}
        maxCols={1}
        onColAdded={(col) => {
          const newInstructions = produce(instructions, (draft) => {
            draft.categoryColumn = resolveCategoryColDropped(col, draft.categoryColumn);
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onColOptionChanged={(option) => {
          const newInstructions = produce(instructions, (draft) => {
            if (draft.categoryColumn) {
              if (isBarChartGroupingColumn) {
                const bucketSize = parseFloat(option.id);
                draft.categoryColumn.bucketSize = isNaN(bucketSize) ? undefined : bucketSize;
              } else {
                draft.categoryColumn.bucket = {
                  id: option.id as PivotAgg,
                  variableId: option.variableId,
                };
              }
            }
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onRemoveCol={() => {
          const newInstructions = produce(instructions, (draft) => {
            draft.categoryColumn = undefined;
          });
          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        schema={schema}
      />
      {instructions.categoryColumn?.bucket?.id === DATE_PART_INPUT_AGG ? (
        <div className={sprinkles({ paddingX: 'sp1.5', paddingBottom: 'sp1.5' })}>
          <Select
            onChange={(elemId) => {
              const newInstructions = produce(instructions, (draft) => {
                if (!draft.categoryColumn) return;

                draft.categoryColumn.bucketElemId = elemId;
              });

              dispatch(updateVisualizeOperation(newInstructions, chartType));
            }}
            placeholder="Select an Input"
            selectedValue={instructions.categoryColumn.bucketElemId}
            values={filterForValidDateGroupElements(dashboardElements).map((elem) => ({
              value: elem.name,
            }))}
          />
        </div>
      ) : null}

      {renderColorBeforeAgg ? renderColorCol() : null}
      <SettingHeader
        btnProps={{
          icon: 'function',
          tooltipText: 'Click to add a custom formula aggregation',
          onClick: () => {
            const newInstructions = produce(instructions, (draft) => {
              draft.aggColumns = resolveAggColDropped(
                { name: 'custom_formula', type: 'string' },
                (toastInfo) => AppToaster.show(toastInfo),
                draft.aggColumns,
                maxAggs,
                instructions.yAxisFormats?.[DEFAULT_Y_AXIS_FORMAT_INDEX].id,
              );

              draft.aggColumns[draft.aggColumns.length - 1].agg = {
                id: Aggregation.FORMULA,
                formula: DEFAULT_CUSTOM_FORMULA,
              };
            });
            dispatch(updateVisualizeOperation(newInstructions, chartType));
          },
        }}
        name={aggName}
      />
      <DroppableColumnSection
        required
        columns={maxAggs ? aggColumns.slice(0, maxAggs) : aggColumns}
        disableEdits={loading}
        maxCols={maxAggs}
        onColAdded={onAggregationColumnAdded}
        onColOptionChanged={(option, name, aggType) => {
          const newInstructions = produce(instructions, (draft) => {
            const changedCol = findMatchingAgg(draft.aggColumns, name, aggType);
            if (!changedCol) return;

            const agg = option as AggregationType;

            if (findMatchingAgg(draft.aggColumns, name, agg)) {
              showCustomToast(
                'The selected aggregation is already present for this column. Duplicates are not allowed.',
                { icon: 'warning-sign' },
              );

              return;
            }

            changedCol.agg = { id: agg.id, formula: agg.formula };
          });

          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        onRemoveCol={(col, aggType) => {
          const newInstructions = produce(instructions, (draft) => {
            const removedColIndex = findMatchingAggColIdx(draft.aggColumns, col.name, aggType);
            if (removedColIndex >= 0) draft.aggColumns?.splice(removedColIndex, 1);
          });

          dispatch(updateVisualizeOperation(newInstructions, chartType));
        }}
        schema={schema}
      />
      {!renderColorBeforeAgg ? renderColorCol() : null}
      {supportSecondaryGroup ? (
        <>
          <SettingHeader name={secondaryGroupName} />
          <DroppableColumnSection
            columns={instructions.groupingColumn ? [instructions.groupingColumn] : []}
            disableEdits={loading}
            maxCols={1}
            onColAdded={(col) =>
              updateGrouping(resolveCategoryColDropped(col, instructions.groupingColumn))
            }
            onColOptionChanged={(option) => {
              if (!instructions.groupingColumn) return;
              updateGrouping({
                ...instructions.groupingColumn,
                bucket: { id: option.id as PivotAgg },
              });
            }}
            onRemoveCol={() => updateGrouping(undefined)}
            schema={schema}
          />
        </>
      ) : null}
    </div>
  );
}
