import { FC } from 'react';

import { Icon, sprinkles } from 'components/ds';
import { DEFAULT_SUPPORT_EMAIL } from 'constants/emailConstants';

export const MaintenancePage: FC<{}> = () => {
  return (
    <div
      className={sprinkles({
        flexItems: 'centerColumn',
        gap: 'sp2',
        textAlign: 'center',
        width: 'fill',
      })}>
      <Icon
        className={sprinkles({ color: 'contentSecondary' })}
        name="circle-exclamation-reg"
        size="lg"
      />
      <h1 className={sprinkles({ color: 'contentPrimary', heading: 'h3', margin: 'sp0' })}>
        This page is unavailable because Explo is under maintenance. Please check back in a couple
        of minutes.
      </h1>
      <p className={sprinkles({ color: 'contentPrimary', body: 'b1', margin: 'sp0' })}>
        Please contact {DEFAULT_SUPPORT_EMAIL} if this is unexpected or if the problem persists.
      </p>
    </div>
  );
};
