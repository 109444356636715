import { createAsyncThunk } from '@reduxjs/toolkit';

import { createApiRequestConfig, createGetRequestConfig } from 'actions/actionUtils';
import { ACTION } from 'actions/types';
import { ROUTES } from 'constants/routes';
import { clearFolderDashboards } from 'reducers/dashboardReducer';
import { clearDashboardVersions } from 'reducers/dashboardVersionsReducer';
import { clearFolders } from 'reducers/folderReducer';
import { clearReportBuilderVersions } from 'reducers/reportBuilderEditReducer';
import { clearReportBuilders } from 'reducers/reportBuilderReducer';
import { ReduxState } from 'reducers/rootReducer';
import {
  Breadcrumb,
  DashboardFolderContents,
  Folder,
  FolderHierarchy,
  ProductType,
  ReportFolderContents,
  ResourceType,
} from 'types/exploResource';
import { makeThunkRequest } from 'utils/thunkUtils';

type ListFolderContentsQueryParams = {
  id?: number;
  page: number;
  page_size: number;
};

export const listDashboardFolderContents = createAsyncThunk<
  DashboardFolderContents,
  ListFolderContentsQueryParams,
  { state: ReduxState }
>(ACTION.LIST_DASHBOARD_FOLDER_CONTENTS, async (queryParams, { dispatch, getState }) => {
  if (getState().folder.productType !== ResourceType.DASHBOARD) {
    dispatch(clearFolders());
    dispatch(clearFolderDashboards());
    dispatch(clearDashboardVersions());
  }

  return makeThunkRequest(
    createGetRequestConfig('resources/list_dashboard_folder_contents/', 'GET', queryParams),
    'Error getting dashboard folder contents',
    {
      onError: () => {
        if (!queryParams.id) return;
        window.location.href = `${window.location.origin}${ROUTES.HOME_APP_PAGE}`;
      },
    },
  );
});

export const listReportFolderContents = createAsyncThunk<
  ReportFolderContents,
  ListFolderContentsQueryParams,
  { state: ReduxState }
>(ACTION.LIST_REPORT_FOLDER_CONTENTS, async (queryParams, { dispatch, getState }) => {
  if (getState().folder.productType !== ResourceType.REPORT) {
    dispatch(clearFolders());
    dispatch(clearReportBuilders());
    dispatch(clearReportBuilderVersions());
  }
  return makeThunkRequest(
    createGetRequestConfig('resources/list_report_folder_contents/', 'GET', queryParams),
    'Error getting report folder contents',
    {
      onError: () => {
        if (!queryParams.id) return;
        window.location.href = `${window.location.origin}${ROUTES.REPORT_BUILDER}`;
      },
    },
  );
});

export const listFolderHierarchy = createAsyncThunk<
  { hierarchy: FolderHierarchy; type: ProductType },
  { type: ProductType },
  { state: ReduxState }
>(ACTION.LIST_FOLDER_HIERARCHY, async (queryParams) =>
  makeThunkRequest(
    createGetRequestConfig('resources/list_folder_hierarchy/', 'GET', queryParams),
    'Error getting folder hierarchy',
  ),
);

type CreateFolderBody = {
  config: { name: string; parent_id: number };
  onSuccess: () => void;
  onError: () => void;
};

export const createFolder = createAsyncThunk<
  { folder: Folder },
  CreateFolderBody,
  { state: ReduxState }
>(ACTION.CREATE_FOLDER, async ({ config, onSuccess, onError }) =>
  makeThunkRequest(createApiRequestConfig(`resources/`, 'POST', config), 'Error creating folder', {
    onSuccess,
    onError,
  }),
);

type RenameFolderBody = { name: string; id: number; onSuccess: () => void };

export const renameFolder = createAsyncThunk<
  { folder: Folder },
  RenameFolderBody,
  { state: ReduxState }
>(ACTION.RENAME_FOLDER, async ({ name, id, onSuccess }) =>
  makeThunkRequest(
    createApiRequestConfig(`resources/${id}/rename/`, 'PUT', { name }),
    'Error renaming folder',
    { onSuccess },
  ),
);

export const deleteFolder = createAsyncThunk<{ id: number }, number, { state: ReduxState }>(
  ACTION.DELETE_FOLDER,
  async (id) =>
    makeThunkRequest(
      createApiRequestConfig(`resources/${id}/`, 'DELETE', undefined),
      'Error deleting folder',
    ),
);

type MoveEntryBody = { parent_id: number; id: number; onError: () => void };
type MoveEntryResponse = {
  entry: { parent_id: number; id: number; type: ResourceType };
  breadcrumbs: Breadcrumb[];
};

export const moveEntry = createAsyncThunk<MoveEntryResponse, MoveEntryBody, { state: ReduxState }>(
  ACTION.MOVE_ENTRY,
  async ({ parent_id, id, onError }) =>
    makeThunkRequest(
      createApiRequestConfig(`resources/move/`, 'PUT', { parent_id, id }),
      'Error moving entry',
      { onError },
    ),
);
