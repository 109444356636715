import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { SettingHeader } from 'components/SettingHeader';
import { IconName } from 'components/ds/Icon';
import { DATE, DATETIME, TIMESTAMP } from 'constants/dataConstants';
import { Aggregation, OPERATION_TYPES, V2TwoDimensionChartInstructions } from 'constants/types';
import { AggregationType } from 'types/columnTypes';
import { DatasetSchema } from 'types/datasets';
import { PivotAgg } from 'types/dateRangeTypes';
import { DEFAULT_CUSTOM_FORMULA } from 'utils/V2ColUtils';
import { getNewAggCol } from 'utils/dataPanelColUtils';

import DroppableColumnSection from './droppable/DroppableColumnSection';

type Props = {
  instructions: V2TwoDimensionChartInstructions | undefined;
  loading: boolean;
  schema: DatasetSchema;
};

export const CalendarHeatmapConfig: FC<Props> = ({ instructions, loading, schema }) => {
  const dispatch = useDispatch();

  const updateInstructions = (updateFunc: (draft: V2TwoDimensionChartInstructions) => void) => {
    const newInstructions = produce(instructions ?? {}, updateFunc);
    dispatch(updateVisualizeOperation(newInstructions, OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP));
  };

  const customFormulaBtnProps =
    instructions?.aggColumns?.[0]?.agg.id === Aggregation.FORMULA
      ? undefined
      : {
          icon: 'function' as IconName,
          tooltipText: 'Click to add a custom formula aggregation',
          onClick: () =>
            updateInstructions((draft) => {
              draft.aggColumns = [
                {
                  column: { name: 'custom_formula' },
                  agg: { id: Aggregation.FORMULA, formula: DEFAULT_CUSTOM_FORMULA },
                },
              ];
            }),
        };

  return (
    <div>
      <SettingHeader name="Date Column" />
      <DroppableColumnSection
        required
        allowedTypes={[DATE, DATETIME, TIMESTAMP]}
        columns={instructions?.categoryColumn ? [instructions.categoryColumn] : []}
        disableEdits={loading}
        maxCols={1}
        onColAdded={(col) =>
          updateInstructions((draft) => {
            draft.categoryColumn = { column: col, bucket: { id: PivotAgg.DATE_PART_MONTH_DAY } };
          })
        }
        onRemoveCol={() =>
          updateInstructions((draft) => {
            draft.categoryColumn = undefined;
          })
        }
        schema={schema}
      />
      <SettingHeader btnProps={customFormulaBtnProps} name="Aggregation" />
      <DroppableColumnSection
        required
        columns={instructions?.aggColumns ?? []}
        disableEdits={loading}
        maxCols={1}
        onColAdded={(newCol) =>
          updateInstructions((draft) => {
            const aggCol = getNewAggCol(newCol);
            if (aggCol) draft.aggColumns = [aggCol];
          })
        }
        onColOptionChanged={(option) =>
          updateInstructions((draft) => {
            if (draft.aggColumns?.length !== 1) return;
            const agg = option as AggregationType;
            draft.aggColumns[0].agg = { id: agg.id, formula: agg.formula };
          })
        }
        onRemoveCol={() =>
          updateInstructions((draft) => {
            draft.aggColumns = [];
          })
        }
        schema={schema}
      />
    </div>
  );
};
