import { FC, useCallback, useMemo, useState } from 'react';

import { Dataset } from 'actions/datasetActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { ReportBuilderVersion } from 'actions/reportBuilderVersionActions';
import { getDatasetName } from 'components/VersionComparisonModal/utils';
import { Modal, sprinkles } from 'components/ds';
import { DashboardVersion } from 'types/dashboardVersion';
import { ResourcePageType } from 'types/exploResource';
import {
  VersionComparatorResult,
  compareDashboardVersions,
  compareReportBuilderVersions,
} from 'utils/resourceVersionComparator';

import { DatasetComparisonSection } from './DatasetComparisonSection';
import { VersionComparisonItemsSection } from './VersionComparisonItemsSection';

interface Props {
  currentVersion: DashboardVersion | ReportBuilderVersion;
  previousVersion: DashboardVersion | ReportBuilderVersion;
  resourceType: ResourcePageType;
  onCloseCallback: () => void;
}

export const VersionComparisonModal: FC<Props> = ({
  previousVersion,
  currentVersion,
  resourceType,
  onCloseCallback,
}) => {
  // NOTE: Using this selectedComparisonItemId as a marker of whether this modal is the base view
  // showing all the different items that have been changed between versions and a detailed view
  // on a particular selected item (currently only datasets). Might want to add in an actual state
  // enum to represent that instead.
  const [selectedComparisonItemId, setSelectedComparisonItemId] = useState<string>('');

  const onCloseWrapperFn = useCallback(() => {
    if (selectedComparisonItemId) {
      // Prevent dialog dismissal when going back from the details view. This will send the user
      // back to the main all items comparison view.
      setSelectedComparisonItemId('');
    } else {
      onCloseCallback();
    }
  }, [selectedComparisonItemId, setSelectedComparisonItemId, onCloseCallback]);

  const versionComparisonResult: VersionComparatorResult = useMemo(() => {
    return resourceType === ResourcePageType.EXPLORE
      ? compareDashboardVersions(
          previousVersion as DashboardVersion,
          currentVersion as DashboardVersion,
        )
      : compareReportBuilderVersions(
          previousVersion as ReportBuilderVersion,
          currentVersion as ReportBuilderVersion,
        );
  }, [previousVersion, currentVersion, resourceType]);

  const { title, subtitle } = getModalTitleAndSubtitleFromModalState(
    selectedComparisonItemId,
    currentVersion,
    previousVersion,
    previousVersion.version_number,
    currentVersion.version_number,
  );

  return (
    <Modal
      isOpen
      className={sprinkles({ overflowY: 'scroll' })}
      onBack={onCloseWrapperFn}
      size="large"
      subtitle={subtitle}
      title={title}>
      {selectedComparisonItemId ? (
        <DatasetComparisonSection
          currentVersionDataset={getSelectedDataset(selectedComparisonItemId, currentVersion)}
          currentVersionNumber={currentVersion.version_number}
          previousVersionDataset={getSelectedDataset(selectedComparisonItemId, previousVersion)}
          previousVersionNumber={previousVersion.version_number}
        />
      ) : (
        <VersionComparisonItemsSection
          setSelectedComparisonItemId={setSelectedComparisonItemId}
          versionComparisonResult={versionComparisonResult}
        />
      )}
    </Modal>
  );
};

function getSelectedDataset(
  datasetId: string,
  version: ReportBuilderVersion | DashboardVersion,
): Dataset | ReportBuilderDataset | undefined {
  if ('config' in version) {
    return version.config.datasets[datasetId];
  } else if ('configuration' in version) {
    return version.configuration.datasets[datasetId];
  }

  throw new Error(
    'Invalid version object. Contains neither a config nor a configuration property.',
  );
}

function getModalTitleAndSubtitleFromModalState(
  selectedComparisonItemId: string,
  currentVersion: DashboardVersion | ReportBuilderVersion,
  previousVersion: DashboardVersion | ReportBuilderVersion,
  previousVersionNumber: number,
  currentVersionNumber: number,
): { title: string; subtitle: string } {
  if (selectedComparisonItemId) {
    const selectedCurrentDataset = getSelectedDataset(selectedComparisonItemId, currentVersion);
    const selectedPreviousDataset = getSelectedDataset(selectedComparisonItemId, previousVersion);
    const selectedDatasetName = selectedCurrentDataset
      ? getDatasetName(selectedCurrentDataset)
      : selectedPreviousDataset
      ? getDatasetName(selectedPreviousDataset)
      : '';

    return {
      title: `${BASE_TITLE} > Dataset: ${selectedDatasetName}`,
      subtitle: '',
    };
  } else {
    return {
      title: BASE_TITLE,
      subtitle: `Version ${previousVersionNumber} to Version ${currentVersionNumber}`,
    };
  }
}

const BASE_TITLE = 'Version Comparison';
