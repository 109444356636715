import { BAR_CHART_TYPES } from 'constants/types';

// TODO(zifanxiang/tstenz): Add more operation types as we support them. Starting with just basic
// bar charts.
export const VALID_DRILLDOWN_OPERATION_TYPES = new Set(BAR_CHART_TYPES);

// These limit constants must be kept in sync with their corresponding constants in
// dashboard_template.py.
export const MAX_CHILD_DASHBOARDS_PER_PARENT = 3;

export const MAX_DASHBOARD_HIERARCHY_DEPTH = 3;
